import { useState, useContext } from "react";
import BigNumber from 'bignumber.js';
import  { withdrawVault } from '../../../blockchain/vaults';
import { trunc } from '../../../utils/commons';

import { ToastContext } from '../../../context/toast';

const VaultWithdrawModal = ({ vaultData, vaultStartDiff }) => {
  const { addToast } = useContext(ToastContext);

  const [pendingTx, setPendingTx] = useState(false);
  const [val, setVal] = useState('');

  const stakedBalance = (mul = 1) => {
    const decimals = vaultData.isTokenOnly ? vaultData.tokenDecimals : process.env.REACT_APP_DECIMALS;

    return trunc(vaultData.stakedWantTokens, mul, decimals);
  }

  const setValue = (newValue) => {
    setVal(newValue);
  }

  const onValChange = (evt) => {
    const { target } = evt;
    setVal(target.value);
  }

  const canWithdraw = () => {
    const value = new BigNumber(val);

    return !value.isNaN() && value.gt(0) && value.lte(stakedBalance());
  }

  const handleWithdraw = async () => {
    const decimals = vaultData.isTokenOnly ? vaultData.tokenDecimals : process.env.REACT_APP_DECIMALS;
    const amount = new BigNumber(val).times(new BigNumber(10).pow(decimals));
    let tx;
    try {
      setPendingTx(true);
      tx = await withdrawVault(vaultData.pid, amount.toFixed());
      await tx.wait();
      setVal('');
      addToast(`${vaultData.isTokenOnly ? '' : 'LP '}Token Withdraw succeeded`, 'is-success');
      setPendingTx(false);
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }

    if (tx.error !== undefined) {
      console.log('error', tx.error);
      addToast(`${vaultData.isTokenOnly ? '' : 'LP '}Token Withdraw failed`, 'is-danger');
      setPendingTx(false);
    }
  }

  return (
    <>
      <div className="buttons is-right mb-0">
        <button className="button is-success is-small is-outlined" onClick={ () => setValue(stakedBalance(0.25).toFixed()) }>
          25%
        </button>
        <button className="button is-success is-small is-outlined" onClick={ () => setValue(stakedBalance(0.5).toFixed()) }>
          50%
        </button>
        <button className="button is-success is-small is-outlined" onClick={ () => setValue(stakedBalance(0.75).toFixed()) }>
          75%
        </button>
        <button className="button is-success is-small is-outlined" onClick={ () => setValue(stakedBalance().toFixed()) }>
          100%
        </button>
      </div>
      <div className="is-flex is-justify-content-end">
        <button className="button is-text" onClick={ () => setValue(stakedBalance().toFixed()) }>
          { stakedBalance().toFormat() } Staked
        </button>
      </div>
      <div className="field has-addons">
        <div className="control is-expanded">
          <input className="input has-text-right" type="text" placeholder="0" value={ val } onChange={ onValChange } />
        </div>
        <div className="control">
          <button
            onClick={ handleWithdraw }
            disabled={ pendingTx || !canWithdraw() || vaultStartDiff > 0 }
            className={ `button is-primary ${pendingTx ? 'is-loading' : ''}` }
          >
            Withdraw
          </button>
        </div>
      </div>
    </>
  );
}

export default VaultWithdrawModal;
