const vaultInitialState = {
  loading: false,
  firstLoad: true,
  tvl: 0,
  maxFarmApy: 0,
  totalBuyBackAmount: 0,
  vaults: [
    {
      pid: 1,
      farmPid: 1,
      token: 'banksy',
      quoteToken: 'avax',
      isTokenOnly: false,
      name: 'BANKSY-WAVAX LP',
      swap: 'Trader Joe',
      from: 'Banksy',
      strategy: '0xd4A2F83DbE8B6A47A8409E035B55AC4C3e09daE8',
    },
    {
      pid: 0,
      farmPid: 0,
      token: 'banksy',
      quoteToken: 'usdt',
      isTokenOnly: false,
      name: 'BANKSY-USDT.e LP',
      swap: 'Trader Joe',
      from: 'Banksy',
      strategy: '0x7B0b76240CF0005414DdfC2F51c1147c3Be26e53',
    },
    {
      pid: 2,
      farmPid: 2,
      token: 'banksy',
      quoteToken: 'usdt',
      isTokenOnly: true,
      name: 'BANKSY',
      swap: 'Trader Joe',
      from: 'Banksy',
      strategy: '0x09be2b6fe25606eD6a325ec6FE3E43d1Bd4CA83a',
    },
  ],
}

export default vaultInitialState;
