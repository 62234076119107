import React from 'react';
import BigNumber from 'bignumber.js';

const NftCard = ({ nftData, hideDetails = false, ribbon = '' }) => {
  return (
    <div className={ `box p-3 is-relative ${nftData.mergeCount > 0 ? 'gradient-border' : ''}` }>
      { ribbon !== '' ? (
        <div className="ribbon ribbon-top-right">
          <span>{ ribbon }</span>
        </div>
      ) : null }
      <figure className="image is-16by9 mb-3">
        <img src={ `/images/nfts/generation-${nftData.generation}.png` } alt="Banksy" />
      </figure>
      <div className="columns is-align-items-center">
        <div className="column">
          <p className="title">{ nftData.name }</p>
        </div>
        {hideDetails ? null : (
          <div className="column is-narrow mb-3">
            <div className="field is-grouped is-grouped-multiline">
              <div className="control">
                <div className="tags has-addons">
                  <span className="tag mb-0 is-dark">Generation</span>
                  <span className="tag mb-0 is-info">{ nftData.generation }</span>
                </div>
              </div>
              <div className="control">
                <div className="tags has-addons">
                  <span className="tag mb-0 is-dark">Experience</span>
                  <span className="tag mb-0 is-success">{ new BigNumber(nftData.experience).div(new BigNumber(10).pow(process.env.REACT_APP_DECIMALS)).toFormat(0) }</span>
                </div>
              </div>
              <div className="control">
                <div className="tags has-addons">
                  <span className="tag mb-0 is-dark">Power</span>
                  <span className="tag mb-0 is-primary">{ nftData.power }</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default NftCard;
