import React, { useState, useContext } from 'react';
import Countdown from 'react-countdown';
import BigNumber from 'bignumber.js';
import  { harvestPool } from '../../../../../blockchain/pools';

import { ToastContext } from '../../../../../context/toast';

const pad = (num) => {
  if (num < 10) {
    return `0${num}`;
  }

  return num;
}

const renderer = ({ hours, minutes, seconds }) => {
  return (
    <span className="ml-1">
      { pad(hours) }:{ pad(minutes) }:{ pad(seconds) }
    </span>
  );
};

const PoolCardHarvestSection = ({ poolData }) => {
  const { addToast } = useContext(ToastContext);

  const [pendingHarvestTx, setPendingHarvestTx] = useState(false);

  const earnings = () => {
    const earningsValue = new BigNumber(poolData.userPendingReward)
      .div(new BigNumber(10).pow(poolData.rewardTokenDecimals));

    return earningsValue.toFormat(earningsValue.gt(1) ? 2 : 6);
  }

  const earningsStable = () => {
    const earningsStableValue = new BigNumber(poolData.userPendingReward)
      .div(new BigNumber(10).pow(poolData.rewardTokenDecimals))
      .times(new BigNumber(poolData.rewardTokenPrice))
      .times(new BigNumber(poolData.networkStablePrice));

    return earningsStableValue.toFormat(earningsStableValue.gt(1) ? 2 : 6);
  }

  const canHarvest = () => {
    const hasPendingTokens = new BigNumber(poolData.userPendingReward).gt(0);

    return hasPendingTokens && !pendingHarvestTx && poolData.userCanHarvest && !poolData.canWithdraw;
  }

  const handleHarvest = async () => {
    setPendingHarvestTx(true);
    let tx;
    try {
      tx = await harvestPool(poolData.address);
      await tx.wait();
      addToast('Wall harvest succeeded', 'is-success');
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }
    if (tx.error !== undefined) {
      console.log('error', tx.error);
      addToast('Wall harvest failed', 'is-danger');
    }
    setPendingHarvestTx(false);
  }

  const harvestButtonText = () => {
    if (poolData.userCanHarvest || poolData.canWithdraw) {
      return 'HARVEST';
    }

    const userNextHarvestUntil = new BigNumber(poolData?.userNextHarvestUntil || 0)
      .times(1000)
      .toNumber();

    return (
      <>
        in <Countdown date={ userNextHarvestUntil } renderer={ renderer } />
      </>
    )
  }

  const experienceEarned = () => {
    const experienceEarnedValue = new BigNumber(poolData.userPendingExperience || 0)
      .plus(poolData.userExperience)
      .div(new BigNumber(10).pow(process.env.REACT_APP_DECIMALS));

    return experienceEarnedValue.toFormat(experienceEarnedValue.gt(1) ? 2 : 8);
  }

  return (
    <>
      <small>EARNED</small>
      <div className="level is-mobile">
        <div className="level-left">
          <div className="level-item">
            <div className="is-flex is-flex-direction-column">
              <h5 className="is-size-5 has-text-weight-semibold">
                { earnings() }
              </h5>
              <h5 className="is-size-5 has-text-weight-semibold">
                ~${ earningsStable() }
              </h5>
            </div>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <div className="is-flex is-flex-direction-column">
              <button disabled={ !canHarvest() } className="button is-primary" onClick={ handleHarvest }>
                { harvestButtonText() }
              </button>
            </div>
          </div>
        </div>
      </div>
      {poolData.userHasNFT ? (
        <div className="level is-mobile">
          <div className="level-left">
            <div className="level-item">
              <div className="is-flex is-flex-direction-column">
                <h5 className="is-size-5 has-text-weight-semibold">
                  { experienceEarned() }
                </h5>
              </div>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <h6 className="is-size-6">NFT Experience</h6>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default PoolCardHarvestSection;
