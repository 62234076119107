const farmInitialState = {
  loading: false,
  firstLoad: true,
  nativeTokenPrice: 0,
  nativeTokenPriceDefault: false,
  tvl: 0,
  tnl: 0,
  maxFarmApr: 0,
  tokenTotalSupply: 0,
  tokenTotalBurned: 0,
  tokenPerBlock: 0,
  tokenMaximumSupply: 0,
  farms: [
    // FARMS
    {
      pid: 1,
      token: 'banksy',
      quoteToken: 'avax',
      isTokenOnly: false,
      name: 'BANKSY-WAVAX LP',
      swap: 'Trader Joe',
    },
    {
      pid: 0,
      token: 'banksy',
      quoteToken: 'usdt',
      isTokenOnly: false,
      name: 'BANKSY-USDT.e LP',
      swap: 'Trader Joe',
    },
    {
      pid: 3,
      token: 'avax',
      quoteToken: 'usdt',
      isTokenOnly: false,
      name: 'WAVAX-USDT.e LP',
      swap: 'Trader Joe',
    },
    {
      pid: 4,
      token: 'usdc',
      quoteToken: 'avax',
      isTokenOnly: false,
      name: 'USDC.e-WAVAX LP',
      swap: 'Trader Joe',
    },
    {
      pid: 5,
      token: 'eth',
      quoteToken: 'avax',
      isTokenOnly: false,
      name: 'WETH.e-WAVAX LP',
      swap: 'Trader Joe',
    },
    {
      pid: 6,
      token: 'usdc',
      quoteToken: 'usdt',
      isTokenOnly: false,
      name: 'USDC.e-USDT.e LP',
      swap: 'Trader Joe',
    },
    // POOLS
    {
      pid: 2,
      token: 'banksy',
      quoteToken: 'usdt',
      isTokenOnly: true,
      name: 'BANKSY',
      swap: 'Trader Joe',
    },
    {
      pid: 7,
      token: 'avax',
      quoteToken: 'usdt',
      isTokenOnly: true,
      name: 'WAVAX',
      swap: 'Trader Joe',
    },
    {
      pid: 10,
      token: 'usdt',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'USDT.e',
      swap: 'Trader Joe',
    },
    {
      pid: 11,
      token: 'usdc',
      quoteToken: 'usdt',
      isTokenOnly: true,
      name: 'USDC.e',
      swap: 'Trader Joe',
    },
    {
      pid: 8,
      token: 'eth',
      quoteToken: 'avax',
      isTokenOnly: true,
      name: 'WETH.e',
      swap: 'Trader Joe',
    },
    {
      pid: 9,
      token: 'btc',
      quoteToken: 'avax',
      isTokenOnly: true,
      name: 'WBTC.e',
      swap: 'Trader Joe',
    },
    {
      pid: 12,
      token: 'link',
      quoteToken: 'avax',
      isTokenOnly: true,
      name: 'LINK.e',
      swap: 'Trader Joe',
    },
  ],
}

export default farmInitialState;
