import React, { useContext, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import EarnNav from '../earn/components/EarnNav';

import { nativeTokenName } from '../../utils/commons';

import PoolCard from './components/PoolCard/PoolCard';

import { fetchPools } from '../../blockchain/pools';
import { PoolsContext } from '../../context/pools';
import { BlockContext } from '../../context/block';

import { NftContext } from '../../context/nft';
import { fetchMyNfts } from '../../blockchain/nft';

import './PoolsPage.css';


const PoolsPage = () => {
  const filters = JSON.parse(localStorage.getItem('poolsFilters'));

  const { poolState, setPoolState } = useContext(PoolsContext);
  const { currentBlock } = useContext(BlockContext);

  const { setNftState } = useContext(NftContext);

  const [stakedOnly, setStakedOnly] = useState(filters ? filters.stakedOnly : false);
  const [activeOnly, setActiveOnly] = useState(filters ? filters.activeOnly : true);

  const saveFilters = (values) => {
    localStorage.setItem('poolsFilters', JSON.stringify({
      stakedOnly,
      activeOnly,
      ...values,
    }));
  }

  const filteredFarms = () => {
    const newPools = poolState.pools.filter(farm => {
      let show = true;
      if (show && stakedOnly) {
        show = new BigNumber(farm.userAmount).gt(0);
      }
      if (show && activeOnly) {
        show = new BigNumber(farm.endBlock).gt(currentBlock);
      } else {
        show = new BigNumber(farm.endBlock).lt(currentBlock);
      }
 
      return show;
    });

    return newPools;
  }

  const onToggleStakedOnly = () => {
    setStakedOnly(prevState => !prevState);
    saveFilters({ stakedOnly: !stakedOnly });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const syncPools = async () => {
      // console.log('>> Sync Farms List >>');
      setPoolState(prevState => ({ ...prevState, loading: true }));
      const newPoolState = await fetchPools();
      setPoolState(prevState => ({ ...prevState, ...newPoolState, loading: false }));
    }

    const intervalId = setInterval(syncPools, 10000);

    syncPools();

    return () => clearInterval(intervalId);
  }, [setPoolState]);

  useEffect(() => {
    const syncMyNfts = async () => {
      setNftState(prevState => ({ ...prevState, loading: true }));
      const nftResult = await fetchMyNfts();
      setNftState(prevState => ({ ...prevState, ...nftResult, loading: false }));
    }

    const intervalId = setInterval(syncMyNfts, 10000);

    syncMyNfts();

    return () => clearInterval(intervalId);
  }, [setNftState]);

  const renderPools = () => {
    if (poolState.firstLoad && poolState.loading) {
      return (
        <div className="has-text-centered">
          <span className="icon-text is-align-items-center">
            <span className="icon is-large">
              <FontAwesomeIcon icon={ faCog } spin size="2x" />
            </span>
            <span>Loading...</span>
          </span>
        </div>
      );
    }

    return (
      <div className="columns is-centered is-multiline">
        {filteredFarms().map(pool => <PoolCard key={ pool.name } poolData={ pool } currentBlock={ currentBlock } />)}
      </div>
    );
  }

  return (
    <>
      <EarnNav />
      <header className="hero parallax" style={{ backgroundImage: 'url("/images/parallax/bg-1.png")' }}>
        <div className="hero-body">
          <div className="container">
            <div className="hero-box has-text-centered">
              <p className="title">Walls</p>
              <p className="subtitle">
                Stake <span className="has-text-primary">{ nativeTokenName }</span> and earn other tokens.<br />
                Reduce Harvest Lockup, earn Experience,<br />
                and Boost your rewards by using your<span className="has-text-primary"> NFTs ArtWork</span>.
              </p>
            </div>
          </div>
        </div>
      </header>
      <main role="main" className="section">
        <div className="container">
          <div className="columns is-justify-content-center is-align-items-center">
            <div className="column is-narrow">
              <div className="field">
                <input id="stakedOnly" type="checkbox" name="stakedOnly" className="switch is-rounded is-outlined" checked={ stakedOnly } onChange={ onToggleStakedOnly } />
                <label htmlFor="stakedOnly">Staked Only</label>
              </div>
            </div>
            <div className="column is-narrow">
              <div className="buttons has-addons">
                <button
                  onClick={() => {
                    if (!activeOnly) {
                      setActiveOnly(true);
                      saveFilters({ activeOnly: true });
                    }
                  }}
                  className={`button is-rounded ${activeOnly ? 'is-success is-selected' : ''}`}
                >
                  Active
                </button>
                <button
                  onClick={() => {
                    if (activeOnly) {
                      setActiveOnly(false);
                      saveFilters({ activeOnly: false });
                    }
                  }}
                  className={`button is-rounded ${activeOnly ? '' : 'is-success is-selected'}`}
                >
                  Finished
                </button>
              </div>
            </div>
          </div>
          { renderPools() }
        </div>
      </main>
      <div className="parallax is-hidden-mobile" style={{ backgroundImage: 'url("/images/parallax/bg-1.png")', height: '100vh' }} />
    </>
  );
}

export default PoolsPage;
