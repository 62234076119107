import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import multicall from '../blockchain/multicall';
import preBanksyAbi from '../config/abis/preBanksy.json';
import preBanksySwapAbi from '../config/abis/preBanksySwap.json';
import masterChefAbi from '../config/abis/masterchef.json';
import nftSaleAbi from '../config/abis/nftSale.json';
import erc20Abi from '../config/abis/erc20.json';
import { getAddress } from '../utils/commons';
import { getSignedContract, getWalletAddress } from './commons';

export const fetchPreSale = async () => {
  const walletAddress = await getWalletAddress();

  const preBanksyAddress = getAddress('preBanksy');
  const preBanksySwapAddress = getAddress('preBanksySwap');
  const nativeTokenAddress = getAddress(process.env.REACT_APP_NATIVE_TOKEN);
  const tokenAddress = getAddress('usdt');
  const masterChefAddress = getAddress('masterChef');

  const preBanksyCalls = [
    {
      address: preBanksyAddress,
      name: 'startBlock',
    },
    {
      address: preBanksyAddress,
      name: 'endBlock',
    },
    {
      address: preBanksyAddress,
      name: 'preBanksyPerAccountMaxTotal',
    },
    {
      address: preBanksyAddress,
      name: 'preBanksyRemaining',
    },
    {
      address: preBanksyAddress,
      name: 'salePriceE35',
    },
    {
      address: preBanksyAddress,
      name: 'decimals'
    },
    {
      address: preBanksyAddress,
      name: 'balanceOf',
      params: [preBanksyAddress],
    },
  ];

  if (walletAddress !== null) {
    preBanksyCalls.push({
      address: preBanksyAddress,
      name: 'allowance',
      params: [
        walletAddress,
        preBanksySwapAddress,
      ],
    });
    preBanksyCalls.push({
      address: preBanksyAddress,
      name: 'balanceOf',
      params: [walletAddress],
    });
    preBanksyCalls.push({
      address: preBanksyAddress,
      name: 'userPreBanksyTotally',
      params: [walletAddress],
    });
  }

  const preBanksySwapCalls = [
    {
      address: preBanksySwapAddress,
      name: 'startBlock',
    },
  ];

  const erc20Calls = [
    {
      address: nativeTokenAddress,
      name: 'balanceOf',
      params: [preBanksySwapAddress],
    },
    {
      address: nativeTokenAddress,
      name: 'decimals',
    },
    {
      address: tokenAddress,
      name: 'decimals',
    },
  ];

  if (walletAddress !== null) {
    erc20Calls.push({
      address: tokenAddress,
      name: 'allowance',
      params: [
        walletAddress,
        preBanksyAddress,
      ],
    });
    erc20Calls.push({
      address: tokenAddress,
      name: 'balanceOf',
      params: [walletAddress],
    });
    erc20Calls.push({
      address: nativeTokenAddress,
      name: 'balanceOf',
      params: [walletAddress],
    });
  }

  const masterChefCalls = [
    {
      address: masterChefAddress,
      name: 'startBlock',
    }
  ];

  const nftSaleCalls = [];

  for(let i = 0; i < 3; i++) {
    nftSaleCalls.push({
      address: getAddress(`nftSale${i}`),
      name: 'startBlock',
    });
  }

  const preBanksyResults = await multicall(preBanksyAbi, preBanksyCalls);
  const preBanksySwapResults = await multicall(preBanksySwapAbi, preBanksySwapCalls);
  const erc20Results = await multicall(erc20Abi, erc20Calls);
  const masterChefResults = await multicall(masterChefAbi, masterChefCalls);
  const nftSaleResults = await multicall(nftSaleAbi, nftSaleCalls);

  return {
    firstLoad: false,

    preBanksyStartBlock: new BigNumber(preBanksyResults[0] || 0).toJSON(),
    preBanksyEndBlock: new BigNumber(preBanksyResults[1] || 0).toJSON(),
    preBanksyPerAccountMaxTotal: new BigNumber(preBanksyResults[2] || 0).toJSON(),
    preBanksyRemaining: new BigNumber(preBanksyResults[3] || 0).toJSON(),
    salePriceE35: new BigNumber(preBanksyResults[4] || 0).toJSON(),
    preBanksyDecimals: preBanksyResults[5] ? preBanksyResults[5][0] : 0,
    preBanksyBalance: new BigNumber(preBanksyResults[6] || 0).toJSON(),
    userPreBanksySwapAllowance: new BigNumber(preBanksyResults[7] || 0).toJSON(),
    userPreBanksyBalance: new BigNumber(preBanksyResults[8] || 0).toJSON(),
    userPreBanksyTotally: new BigNumber(preBanksyResults[9] || 0).toJSON(),

    preBanksySwapStartBlock: new BigNumber(preBanksySwapResults[0] || 0).toJSON(),

    preBanksySwapBalance: new BigNumber(erc20Results[0] || 0).toJSON(),
    nativeTokenDecimals: erc20Results[1] ? erc20Results[1][0] : 0,
    tokenDecimals: erc20Results[2] ? erc20Results[2][0] : 0,
    userPreBanksyAllowance: new BigNumber(erc20Results[3] || 0).toJSON(),
    userTokenBalance: new BigNumber(erc20Results[4] || 0).toJSON(),
    userNativeTokenBalance: new BigNumber(erc20Results[5] || 0).toJSON(),

    masterChefStartBlock: new BigNumber(masterChefResults[0] || 0).toJSON(),

    nftSale0StartBlock: new BigNumber(nftSaleResults[0] || 0).toJSON(),
    nftSale1StartBlock: new BigNumber(nftSaleResults[1] || 0).toJSON(),
    nftSale2StartBlock: new BigNumber(nftSaleResults[2] || 0).toJSON(),

    soldOut: new BigNumber(preBanksyResults[3] || 0).eq(0),
    salePrice: (new BigNumber(10).pow(35)).div(preBanksyResults[4] || 0).toJSON(),
  };
}

export const approveBuyPreBanksy = async (token) => {
  const tokenContract = await getSignedContract(token, erc20Abi);
  return await tokenContract.approve(getAddress('preBanksy'), ethers.constants.MaxUint256);
}

export const approveSwapPreBanksy = async () => {
  const tokenContract = await getSignedContract('preBanksy', preBanksyAbi);
  return await tokenContract.approve(getAddress('preBanksySwap'), ethers.constants.MaxUint256);
}

export const swapPreBanksyForBanksy = async () => {
  const preBanksySwapContract = await getSignedContract('preBanksySwap', preBanksySwapAbi);
  return await preBanksySwapContract.swapPreBanksyForBanksy();
}

export const buyPreBanksy = async (amount) => {
  const preBanksyContract = await getSignedContract('preBanksy', preBanksyAbi);
  return await preBanksyContract.buyPreBanksy(amount);
}
