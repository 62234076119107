import React, { useContext, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';

import HomeNav from '../home/components/HomeNav';

import confetti from 'canvas-confetti';

import { fetchAntiBot, blackListCheck } from '../../blockchain/antiBot';
import { ToastContext } from '../../context/toast';

const AntiBotPage = () => {
  const { addToast } = useContext(ToastContext);

  const [val, setVal] = useState('');
  const [failureMsg, setFailureMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [pendingTx, setPendingTx] = useState(false);
  const [antiBotState, setAntiBotState] = useState({
    loading: false,
    antiBotWorking: false,
    maxUserHoldAmount: 0,
    maxUserTransferAmount: 0,
    blacklistLength: 0,
    firstLoad: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const syncAntiBot = async () => {
      setAntiBotState(prevState => ({ ...prevState, loading: true }));
      const rs = await fetchAntiBot();
      setAntiBotState(prevState => ({ ...prevState, ...rs, firstLoad: false }));
    }

    const intervalId = setInterval(syncAntiBot, 5000);

    syncAntiBot();

    return () => clearInterval(intervalId);
  }, [setAntiBotState]);

  const showConfetti = () => {
    confetti({
      resize: true,
      particleCount: 200,
      startVelocity: 30,
      gravity: 0.5,
      spread: 350,
      origin: {
        x: 0.5,
        y: 0.3,
      },
    });
  }

  const onValChange = (evt) => {
    const { target } = evt;
    setVal(target.value);
  }

  const handleBlacklistCheck = async () => {
    setPendingTx(true);
    setSuccessMsg('');
    setFailureMsg('');

    try {
      const isBlackListed = await blackListCheck(val);
  
      if (isBlackListed) {
        setFailureMsg('The address was found on our Antibot System Blacklist. Please contact Customer Support on Telegram Main Channel.');
      } else {
        setSuccessMsg("The address wasn't found on our Antibot System Blacklist. You can farming safely with BANKSY FARM.");
        showConfetti();
      }
    } catch (error) {
      addToast('Invalid address', 'is-danger');
    }

    setPendingTx(false);
  }

  const showState = (st) => {
    if (st) {
      return <strong className="has-text-success">ENABLED</strong>;
    }

    return <strong className="has-text-danger">DISABLED</strong>;
  }

  return (
    <>
      <HomeNav />
      <header className="hero parallax" style={{ backgroundImage: 'url("/images/parallax/bg-1.png")' }}>
        <div className="hero-body">
          <div className="container">
            <div className="hero-box has-text-centered">
              <p className="title">ANTI-BOT System</p>
              <p className="subtitle">Enter an address to check if it is banned</p>
            </div>
          </div>
        </div>
      </header>
      <main role="main" className="section">
        <div className="container">
          <div className="field has-addons">
            <div className="control is-expanded">
              <input
                className="input has-text-right is-large"
                type="text"
                placeholder="Enter your Address"
                value={ val }
                onChange={ onValChange }
              />
            </div>
            <div className="control">
              <button
                type="button"
                className={ `button is-info is-large ${pendingTx ? 'is-loading' : ''}` }
                disabled={ pendingTx || !/^0x\w{40}$/.test(val) }
                onClick={ handleBlacklistCheck }
              >
                Check
              </button>
            </div>
          </div>
          { successMsg !== '' ? (
            <div className="notification is-success mb-3">{ successMsg }</div>
          ) : null }
          { failureMsg !== '' ? (
            <div className="notification is-danger mb-3">{ failureMsg }</div>
          ) : null }
          <div className="columns is-justify-content-center">
            <div className="column is-half">
              <div className="box p-3 content">
                <h3>ANTI-BOT Status</h3>
                <p>
                  Max Amount TX Checker:<br />
                  { showState(antiBotState.antiBotWorking) }
                </p>
                <p>
                  Max Amount HOLD Checker:<br />
                  { showState(antiBotState.antiBotWorking) }
                </p>
                <p className="mb-0">
                  Blacklist Checker:<br />
                  { showState(true) }
                </p>
              </div>
            </div>
            <div className="column is-half">
              <div className="box p-3 content">
                <h3>ANTI-BOT Stats</h3>
                <p>
                  Total Addresses Banned:<br />
                  <strong className="has-text-primary">{ new BigNumber(antiBotState.blacklistLength).toFormat(0) }</strong>
                </p>

                {antiBotState.antiBotWorking ? (
                  <>
                    <p>
                      Max Transaction Amount:<br />
                      <strong className="has-text-primary">{ new BigNumber(antiBotState.maxUserTransferAmount).div(new BigNumber(10).pow(antiBotState.decimals)).toFormat(2) }</strong>
                    </p>
                    <p>
                      Max Hold Amount:<br />
                      <strong className="has-text-primary">{ new BigNumber(antiBotState.maxUserHoldAmount).div(new BigNumber(10).pow(antiBotState.decimals)).toFormat(2) }</strong>
                    </p>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className="parallax is-hidden-mobile" style={{ backgroundImage: 'url("/images/parallax/bg-1.png")', height: '100vh' }} />
    </>
  );
}

export default AntiBotPage;
