import React, { createContext, useState, useEffect } from 'react';
import { getDefaultProvider } from '../blockchain/commons'; 

export const BlockContext = createContext();

export const BlockProvider = (props) => {
  const [currentBlock, setCurrentBlock] = useState(0);

  useEffect(() => {
    const getBlock = async () => {
      const provider = await getDefaultProvider();
      const newBlock = await provider.getBlockNumber();
      setCurrentBlock(newBlock || 0);
    }
    const intervalId = setInterval(getBlock, 5000);
    getBlock();
    return () => clearInterval(intervalId);
  }, [setCurrentBlock]);

  return (
    <BlockContext.Provider value={{ currentBlock }}>
      { props.children }
    </BlockContext.Provider>
  );
}
