import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import multicall from './multicall';
import erc20Abi from '../config/abis/erc20.json';
import poolsV1Abi from '../config/abis/poolsV1.json';
import { getAddress, getLpAddress } from '../utils/commons';
import { getSignedContract, getWalletAddress } from './commons';

import poolInitialState from '../state/poolInitialState';

const ZERO = new BigNumber(0);

export const fetchPools = async () => {
  let erc20Calls = [];
  let poolV1Calls = [];

  const walletAddress = await getWalletAddress();  
  let isUserConnected = false;
  if(walletAddress !==null )
    isUserConnected = true;

  poolInitialState.pools.forEach(pool => {
    const stakedTokenAddress = getAddress(pool.stakedToken);
    const rewardTokenAddress = getAddress(pool.rewardToken);
    const networkTokenAddress = getAddress(process.env.REACT_APP_NETWORK_TOKEN);
    const stableTokenAddress = getAddress(process.env.REACT_APP_STABLE_TOKEN);
    const stakedTokenLpAddress = getLpAddress(pool.stakedToken, process.env.REACT_APP_NETWORK_TOKEN);
    const rewardTokenLpAddress = getLpAddress(pool.rewardToken, process.env.REACT_APP_NETWORK_TOKEN);
    const stableNetworkLpAddress = getLpAddress(process.env.REACT_APP_STABLE_TOKEN, process.env.REACT_APP_NETWORK_TOKEN);
    const poolAddress = pool.address;

    const calls = [
      {
        address: stakedTokenAddress,
        name: 'balanceOf',
        params: [stakedTokenLpAddress],
      },
      {
        address: networkTokenAddress,
        name: 'balanceOf',
        params: [stakedTokenLpAddress],
      },
      {
        address: rewardTokenAddress,
        name: 'balanceOf',
        params: [rewardTokenLpAddress],
      },
      {
        address: networkTokenAddress,
        name: 'balanceOf',
        params: [rewardTokenLpAddress],
      },
      {
        address: stakedTokenAddress,
        name: 'balanceOf',
        params: [poolAddress],
      },
      {
        address: stableTokenAddress,
        name: 'balanceOf',
        params: [stableNetworkLpAddress],
      },
      {
        address: networkTokenAddress,
        name: 'balanceOf',
        params: [stableNetworkLpAddress],
      },
      {
        address: stakedTokenAddress,
        name: 'decimals',
      },
      {
        address: rewardTokenAddress,
        name: 'decimals',
      },
      {
        address: stableTokenAddress,
        name: 'decimals',
      },
      {
        address: networkTokenAddress,
        name: 'decimals',
      },
    ];

    if(isUserConnected) {
      calls.push(
        {
          address: stakedTokenAddress,
          name: 'allowance',
          params: [
            walletAddress,
            poolAddress
          ],
        },
      );
      calls.push(
        {
          address: stakedTokenAddress,
          name: 'balanceOf',
          params: [walletAddress],
        },
      );
    }

    erc20Calls = [...erc20Calls, ...calls];

    const calls2 = [
      {
        address: poolAddress,
        name: 'startBlock',
        params: [],
      },
      {
        address: poolAddress,
        name: 'endBlock',
      },
      {
        address: poolAddress,
        name: 'canWithdraw',
      },
      {
        address: poolAddress,
        name: 'rewardPerBlock',
      },
      // {
      //   address: poolAddress,
      //   name: 'roundCounter',
      // },
      {
        address: poolAddress,
        name: 'harvestInterval',
      },
      {
        address: poolAddress,
        name: 'experiencePerBlock',
      }
    ];

    if(isUserConnected) {
      // calls2.push({
      //   address: poolAddress,
      //   name: 'canClaimHarvest',
      //   params: [walletAddress],
      // });
      calls2.push({
        address: poolAddress,
        name: 'canHarvest',
        params: [walletAddress],
      });
      // calls2.push({
      //   address: poolAddress,
      //   name: 'ledger',
      //   params: [walletAddress],
      // });
      calls2.push({
        address: poolAddress,
        name: 'pendingReward',
        params: [walletAddress],
      });
      calls2.push({
        address: poolAddress,
        name: 'pendingExperience',
        params: [walletAddress],
      });
      calls2.push({
        address: poolAddress,
        name: 'userInfo',
        params: [walletAddress],
      });
      // calls2.push({
      //   address: poolAddress,
      //   name: 'userIsPlayingNow',
      //   params: [walletAddress],
      // });
    }

    poolV1Calls = [...poolV1Calls, ...calls2];
  });

  const erc20Results = await multicall(erc20Abi, erc20Calls);
  const poolV1Results = await multicall(poolsV1Abi, poolV1Calls);

  const erc20Length = erc20Results.length / poolInitialState.pools.length;
  const poolV1Length = poolV1Results.length / poolInitialState.pools.length;

  // const roundCountersCalls = [];

  // poolInitialState.pools.forEach((pool, i) => {
  //   const poolV1Index = i * poolV1Length;
  //   const roundCounter = new BigNumber(poolV1Results[poolV1Index + 4]);

  //   roundCountersCalls.push({
  //     address: pool.address,
  //     name: 'rounds',
  //     params: [BigNumber.maximum(roundCounter.minus(1), 0).toString()]
  //   });
  //   roundCountersCalls.push({
  //     address: pool.address,
  //     name: 'rounds',
  //     params: [BigNumber.maximum(roundCounter, 0).toString()]
  //   });
  // });

  // const roundCountersResults = await multicall(poolsV1Abi, roundCountersCalls);

  // const roundCountersLength = roundCountersResults.length / poolInitialState.pools.length;

  let tvl = ZERO;
  let maxPoolApr = ZERO;

  const newPools = poolInitialState.pools.map((pool, i) => {
    const erc20Index = i * erc20Length;
    const poolV1Index = i * poolV1Length;
    // const roundCounterIndex = i * roundCountersLength;

    const stakedTokenBalanceLP = new BigNumber(erc20Results[erc20Index + 0]);
    const stakedQuoteTokenBalanceLP = new BigNumber(erc20Results[erc20Index + 1]);
    const rewardTokenBalanceLP = new BigNumber(erc20Results[erc20Index + 2]);
    const rewardQuoteTokenBalanceLP = new BigNumber(erc20Results[erc20Index + 3]);
    const totalStaked = new BigNumber(erc20Results[erc20Index + 4]);
    const stableTokenBalanceLP = new BigNumber(erc20Results[erc20Index + 5]);
    const networkTokenBalanceLP = new BigNumber(erc20Results[erc20Index + 6]);
    const stakedTokenDecimals = erc20Results[erc20Index + 7][0];
    const rewardTokenDecimals = erc20Results[erc20Index + 8][0];
    const stableTokenDecimals = erc20Results[erc20Index + 9][0];
    const networkTokenDecimals = erc20Results[erc20Index + 10][0];
    const userAllowance = isUserConnected ? new BigNumber(erc20Results[erc20Index + 11]) : ZERO;
    const userBalance =  isUserConnected ? new BigNumber(erc20Results[erc20Index + 12]) : ZERO;
    const startBlock = new BigNumber(poolV1Results[poolV1Index + 0]);
    const endBlock = new BigNumber(poolV1Results[poolV1Index + 1]);
    const canWithdraw = poolV1Results[poolV1Index + 2][0];
    const rewardPerBlock = new BigNumber(poolV1Results[poolV1Index + 3]);
    // const roundCounter = new BigNumber(poolV1Results[poolV1Index + 4]);
    const harvestInterval = new BigNumber(poolV1Results[poolV1Index + 4]);
    const experiencePerBlock = new BigNumber(poolV1Results[poolV1Index + 5]);
    // const userCanClaimHarvest = isUserConnected ? poolV1Results[poolV1Index + 7][0] : false;
    const userCanHarvest = isUserConnected ? poolV1Results[poolV1Index + 6][0] : false;
    // const userRoundNumber = isUserConnected ? new BigNumber(poolV1Results[poolV1Index + 9].roundNumber._hex) : ZERO;
    // const userLastRoundHarvest = isUserConnected ? new BigNumber(poolV1Results[poolV1Index + 9].lastRoundHarvest) : ZERO;
    // const userPosition = isUserConnected ? new BigNumber(poolV1Results[poolV1Index + 9].position) : ZERO;
    const userPendingReward = isUserConnected ? new BigNumber(poolV1Results[poolV1Index + 7]) : ZERO;
    const userPendingExperience = isUserConnected ? new BigNumber(poolV1Results[poolV1Index + 8]) : ZERO;
    const userAmount = isUserConnected ? new BigNumber(poolV1Results[poolV1Index + 9].amount._hex) : ZERO;
    const userHasNFT = isUserConnected ? poolV1Results[poolV1Index + 9].hasNFT : false;
    const userNftID = isUserConnected ? new BigNumber(poolV1Results[poolV1Index + 9].nftID._hex) : ZERO;
    const userExperience = isUserConnected ? new BigNumber(poolV1Results[poolV1Index + 9].experience._hex) : ZERO;
    const userNextHarvestUntil = isUserConnected ? new BigNumber(poolV1Results[poolV1Index + 9].nextHarvestUntil._hex) : ZERO;
    // const userIsPlayingNow = isUserConnected ? poolV1Results[poolV1Index + 13][0] : false;

    // const lastRoundPositionWinner = new BigNumber(roundCountersResults[roundCounterIndex + 0].positionWinner);
    // const roundStart = new BigNumber(roundCountersResults[roundCounterIndex + 1].roundStart._hex);
    // const lockPrice = new BigNumber(roundCountersResults[roundCounterIndex + 1].lockPrice._hex);

    const networkStablePrice = stableTokenBalanceLP
      .times(new BigNumber(10).pow(networkTokenDecimals - stableTokenDecimals))
      .div(networkTokenBalanceLP);

    let stakedTokenPrice = stakedQuoteTokenBalanceLP.div(stakedTokenBalanceLP);
    if (stakedTokenPrice.isNaN() || !stakedTokenPrice.isFinite()) {
      stakedTokenPrice = new BigNumber(pool.stakedTokenDefaultPrice).div(networkStablePrice);
    }
    const rewardTokenPrice = rewardQuoteTokenBalanceLP.div(rewardTokenBalanceLP);

    const totalRewardPricePerYear = rewardTokenPrice
      .times(rewardPerBlock.div(new BigNumber(10).pow(process.env.REACT_APP_DECIMALS)))
      .times(process.env.REACT_APP_BLOCKS_PER_YEAR);

    const totalStakingTokenInPool = stakedTokenPrice
      .times(totalStaked.div(new BigNumber(10).pow(stakedTokenDecimals)));

    let apr = totalRewardPricePerYear;

    if (totalStakingTokenInPool.gt(0)) {
      apr = apr.div(totalStakingTokenInPool);
    }

    if (apr.gt(maxPoolApr)) {
      maxPoolApr = apr;
    }

    const blocks = endBlock.minus(startBlock);
    const withdrawLockup = blocks.div(process.env.REACT_APP_BLOCKS_PER_HOUR);
    const experienceReward = blocks.times(experiencePerBlock.div(new BigNumber(10).pow(process.env.REACT_APP_DECIMALS)));
    const poolReward = blocks.times(rewardPerBlock.div(new BigNumber(10).pow(rewardTokenDecimals)));

    tvl = tvl.plus(totalStaked.div(new BigNumber(10).pow(stakedTokenDecimals))
      .times(new BigNumber(stakedTokenPrice))
      .times(new BigNumber(networkStablePrice)));

    return {
      ...pool,
      apr: apr.toJSON(),
      stakedTokenPrice: stakedTokenPrice.toJSON(),
      rewardTokenPrice: rewardTokenPrice.toJSON(),
      networkStablePrice: networkStablePrice.toJSON(),
      stakedTokenBalanceLP: stakedTokenBalanceLP.toJSON(),
      stakedQuoteTokenBalanceLP: stakedQuoteTokenBalanceLP.toJSON(),
      rewardTokenBalanceLP: rewardTokenBalanceLP.toJSON(),
      rewardQuoteTokenBalanceLP: rewardQuoteTokenBalanceLP.toJSON(),
      totalStaked: totalStaked.toJSON(),
      stableTokenBalanceLP: stableTokenBalanceLP.toJSON(),
      networkTokenBalanceLP: networkTokenBalanceLP.toJSON(),
      stakedTokenDecimals,
      rewardTokenDecimals,
      stableTokenDecimals,
      networkTokenDecimals,
      userAllowance: userAllowance.toJSON(),
      userBalance: userBalance.toJSON(),
      startBlock: startBlock.toJSON(),
      endBlock: endBlock.toJSON(),
      canWithdraw,
      rewardPerBlock: rewardPerBlock.toJSON(),
      // roundCounter: roundCounter.toJSON(),
      harvestInterval: harvestInterval.toJSON(),
      // userCanClaimHarvest,
      userCanHarvest,
      // userRoundNumber: userRoundNumber.toJSON(),
      // userLastRoundHarvest: userLastRoundHarvest.toJSON(),
      // userPosition: userPosition.toJSON(),
      userPendingReward: userPendingReward.toJSON(),
      userPendingExperience: userPendingExperience.toJSON(),
      userAmount: userAmount.toJSON(),
      userHasNFT,
      userNftID: userNftID.toJSON(),
      userExperience: userExperience.toJSON(),
      userNextHarvestUntil: userNextHarvestUntil.toJSON(),
      // userIsPlayingNow,
      withdrawLockup: withdrawLockup.toJSON(),
      experienceReward: experienceReward.toJSON(),
      poolReward: poolReward.toJSON(),
      poolRewardStable: poolReward.times(rewardTokenPrice).times(networkStablePrice).toJSON(),
      // lastRoundPositionWinner: lastRoundPositionWinner.toJSON(),
      // roundStart: roundStart.toJSON(),
      // lockPrice: lockPrice.toJSON(),
    }
  });

  return {
    pools: newPools,
    tvl: tvl.toJSON(),
    maxPoolApr: maxPoolApr.toJSON(),
    firstLoad: false,
  }
};

export const approvePool = async (address, token) => {
  const tokenContract = await getSignedContract(token, erc20Abi);

  return await tokenContract.approve(address, ethers.constants.MaxUint256);
}

export const withdrawPool = async (address) => {
  const poolContract = await getSignedContract(address, poolsV1Abi);

  return await poolContract.withdraw();
}

export const depositPool = async (address, amount, nftID) => {
  const poolContract = await getSignedContract(address, poolsV1Abi);

  return await poolContract.deposit(amount, nftID);
}

export const harvestPool = async (address) => {
  return await depositPool(address, '0', '201');
}

// Prediction Game
// export const betBear = async (address) => {
//   const poolContract = await getSignedContract(address, poolsV1Abi);

//   return await poolContract.betBear();
// }

// export const betBull = async (address) => {
//   const poolContract = await getSignedContract(address, poolsV1Abi);

//   return await poolContract.betBull();
// }

// export const claimHarvest = async (address) => {
//   const poolContract = await getSignedContract(address, poolsV1Abi);

//   return await poolContract.claimHarvest();
// }
