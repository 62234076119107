const poolInitialState = {
  loading: false,
  tvl: 0,
  maxPoolApr: 0,
  firstLoad: true,
  pools: [
    {
      pid: 6,
      address: '0x7B2Cf5510f6e1b2C0427302e7D6A8F9672e946B2',
      stakedToken: 'banksy',
      rewardToken: 'dai',
      name: 'BANKSY DAI WALL',
      projectLink: 'https://makerdao.com/en/',
      category: 'CORE',
      version: 'v1',
    },
    {
      pid: 5,
      address: '0x212AB52b47A65A937B573df2fF6dA11874f32e43',
      stakedToken: 'banksy',
      rewardToken: 'dai',
      name: 'BANKSY DAI WALL',
      projectLink: 'https://makerdao.com/en/',
      category: 'CORE',
      version: 'v1',
    },
    {
      pid: 4,
      address: '0x0D4728e990F0d56A1E2164B8661d299e85a3CaB6',
      stakedToken: 'banksy',
      rewardToken: 'sing',
      name: 'SING WALL',
      projectLink: 'https://singular.farm/',
      category: 'PARTNERSHIP',
      version: 'v1',
    },
    {
      pid: 3,
      address: '0x49521E518f1e6C41CeFE69b06984B55f5863B411',
      stakedToken: 'banksy',
      rewardToken: 'dai',
      name: 'BANKSY XMASS WALL',
      projectLink: 'https://makerdao.com/en/',
      category: 'CORE',
      version: 'v1',
    },
    {
      pid: 2,
      address: '0x8B8F60F4F5184BB1320A7ba7F9Ac1bc7CA6aAc90',
      stakedToken: 'banksy',
      rewardToken: 'dai',
      name: 'BANKSY DAI WALL',
      projectLink: 'https://makerdao.com/en/',
      category: 'CORE',
      version: 'v1',
      stakedTokenDefaultPrice: 3.00,
    },
    {
      pid: 1,
      address: '0x9370261cABfA8bE22365d803eB46F3437515a981',
      stakedToken: 'preBanksy',
      rewardToken: 'dai',
      name: 'PRE-BANKSY DAI WALL',
      projectLink: 'https://makerdao.com/en/',
      category: 'CORE',
      version: 'v1',
      stakedTokenDefaultPrice: 2.00,
    },
  ],
};

export default poolInitialState;
