import React from 'react';
import BigNumber from 'bignumber.js';
import { faSync, faTractor } from '@fortawesome/free-solid-svg-icons';
import { faFortAwesome } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUpNumber from '../../../layout/components/CountUpNumber';
import { nativeTokenName } from '../../../utils/commons';

const TotalValueLockedSection = ({ farmState, poolState, vaultState }) => {
  const farmsTvl = new BigNumber(farmState.tvl);
  const poolsTvl = new BigNumber(poolState.tvl);
  const vaultsTvl = new BigNumber(vaultState.tvl);
  const tvl = farmsTvl.plus(poolsTvl);

  const decimals = new BigNumber(10).pow(process.env.REACT_APP_DECIMALS);

  const tokenTotalSupply = new BigNumber(farmState.tokenTotalSupply).div(decimals);
  const tokenTotalBurned = new BigNumber(farmState.tokenTotalBurned).div(decimals);
  const tokenMaximumSupply = new BigNumber(farmState.tokenMaximumSupply).div(decimals);
  const tokenPerBlock = new BigNumber(farmState.tokenPerBlock).div(decimals);
  const marketCap = new BigNumber(farmState.nativeTokenPrice).times(tokenTotalSupply);
  const circulatingSupply = tokenTotalSupply.minus(tokenTotalBurned);

  return (
    <div className="box">
      <div className="columns">
        <div className="column is-three-quarters">
          <p className="subtitle">Total Value Locked</p>
          <p className="title is-1">$<CountUpNumber value={ farmState.firstLoad ? 0 : tvl.toNumber() } decimals={ 0 } /></p>

          <div className="columns">
            <div className="column is-narrow">
              <div className="icon-text">
                <span className="icon is-large has-text-info">
                  <FontAwesomeIcon icon={ faTractor } size="2x" />
                </span>
                <div className="is-flex is-flex-direction-column">
                  <p className="title is-4">
                    $<CountUpNumber value={ farmsTvl.toNumber() } decimals={ 0 } />
                  </p>
                  <p className="subtitle is-6 has-text-info">Farms</p>
                </div>
              </div>
            </div>
            <div className="column is-narrow">
              <div className="icon-text">
                <span className="icon is-large has-text-success">
                  <FontAwesomeIcon icon={ faFortAwesome } size="2x" />
                </span>
                <div className="is-flex is-flex-direction-column">
                  <p className="title is-4">${ poolsTvl.toFormat(0) }</p>
                  <p className="subtitle is-6 has-text-success">Walls</p>
                </div>
              </div>
            </div>
            <div className="column is-narrow">
              <div className="icon-text">
                <span className="icon is-large has-text-warning">
                  <FontAwesomeIcon icon={ faSync } size="2x" />
                </span>
                <div className="is-flex is-flex-direction-column">
                  <p className="title is-4">${ vaultsTvl.toFormat(0) }</p>
                  <p className="subtitle is-6 has-text-warning">Vaults</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-one-quarter is-hidden-mobile">
          <figure className="image is-square">
            <img src="/images/coin-xmass.png" alt="Banksy Farm" />
          </figure>
        </div>
      </div>
      <hr />
      <h3 className="subtitle has-text-centered-mobile has-text-left-dekstop">{ nativeTokenName } Stats</h3>
      <nav className="columns is-multiline is-mobile is-align-items-flex-end">
        <div className="column is-half-mobile is-one-third-desktop has-text-centered">
          <div>
            <p className="heading">MarketCap</p>
            <p className="title is-4">${ marketCap.toFormat(0) }</p>
          </div>
        </div>
        <div className="column is-half-mobile is-one-third-desktop has-text-centered">
          <div>
            <p className="heading">Total Minted</p>
            <p className="title is-4">{ tokenTotalSupply.toFormat(0) }</p>
          </div>
        </div>
        <div className="column is-half-mobile is-one-third-desktop has-text-centered">
          <div>
            <p className="heading">Circulating Supply</p>
            <p className="title is-4">{ circulatingSupply.toFormat(0) }</p>
          </div>
        </div>
        <div className="column is-half-mobile is-one-third-desktop has-text-centered">
          <div>
            <p className="heading">Max Supply</p>
            <p className="title is-4">{ tokenMaximumSupply.toFormat(0) }</p>
          </div>
        </div>
        <div className="column is-half-mobile is-one-third-desktop has-text-centered">
          <div>
            <p className="heading">{ nativeTokenName }/Block</p>
            <p className="title is-4">{ tokenPerBlock.toFormat(2) }</p>
          </div>
        </div>
        <div className="column is-half-mobile is-one-third-desktop has-text-centered">
          <div>
            <p className="heading">Insane Provided Liquidity</p>
            <p className="title is-4 has-text-primary">$141,456</p>
          </div>
        </div>


        <div className="column is-half-mobile is-one-third-desktop has-text-centered">
          <div>
            <p className="heading">Timelock</p>
            <p className="title is-4 has-text-primary">24hs</p>
          </div>
        </div>
        <div className="column is-half-mobile is-one-third-desktop has-text-centered">
          <div>
            <p className="heading">ANTI-BOT</p>
            <p className="title is-4 has-text-danger">OFF</p>
          </div>
        </div>
        <div className="column is-half-mobile is-one-third-desktop has-text-centered">
          <div>
            <p className="heading">BOOSTED</p>
            <p className="title is-4 has-text-danger">OFF</p>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default TotalValueLockedSection;
