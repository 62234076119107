import React, { useContext, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import confetti from 'canvas-confetti';
import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import NftNav from './components/NftNav';
import NftCard from './components/NftCard';
import NftInfo from './components/NftInfo';

import { fetchNftAirdrop, claimNftAirdrop } from '../../blockchain/nft';

import { WalletContext } from '../../context/wallet';
import { BlockContext } from '../../context/block';
import { ToastContext } from '../../context/toast';

const NftAirdropPage = () => {
  const { walletAddress, handleConnectClick } = useContext(WalletContext);
  const { currentBlock } = useContext(BlockContext);
  const { addToast } = useContext(ToastContext);

  const [pendingTx, setPendingTx] = useState(false);
  const [startDiff, setStartDiff] = useState(0);
  const [endDiff, setEndDiff] = useState(0);
  const [nftAirdropState, setNftAirdropState] = useState({
    endBlock: 0,
    userCanClaim: false,
    firstLoad: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const syncNftAirdrop = async () => {
      const rs = await fetchNftAirdrop();
      setNftAirdropState(prevState => ({ ...prevState, ...rs, firstLoad: false }));
    }

    const intervalId = setInterval(syncNftAirdrop, 5000);

    syncNftAirdrop();

    return () => clearInterval(intervalId);
  }, [setNftAirdropState]);

  useEffect(() => {
    if (currentBlock) {
      setStartDiff(Number(process.env.REACT_APP_NFT_AIRDROP_START) - currentBlock);

      setEndDiff(new BigNumber(nftAirdropState.endBlock).minus(currentBlock).toNumber());
    }
  }, [currentBlock, setStartDiff, setEndDiff, nftAirdropState]);

  const showConfetti = () => {
    confetti({
      resize: true,
      particleCount: 200,
      startVelocity: 30,
      gravity: 0.5,
      spread: 350,
      origin: {
        x: 0.5,
        y: 0.3,
      },
    });
  }

  const handleClaimNft = async () => {
    let tx;
    try {
      setPendingTx(true);
      tx = await claimNftAirdrop();
      await tx.wait();
      addToast('NFT Airdrop claim succeeded', 'is-success');
      showConfetti();
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }

    if(tx?.error !== undefined) {
      console.log('error', tx.error);
      addToast('NFT Airdrop claim failed', 'is-danger');
    }

    setPendingTx(false);
  }

  const renderApproveOrClaimButton = () => {
    if (walletAddress === null) {
      return (
        <button className="button is-primary is-fullwidth" onClick={ handleConnectClick }>
          Unlock
        </button>
      );
    }

    return (
      <button
        disabled={ pendingTx || !nftAirdropState.userCanClaim || startDiff > 0 || endDiff < 0 }
        type="button"
        className={ `button is-primary is-fullwidth ${pendingTx ? 'is-loading' : ''}` }
        onClick={ handleClaimNft }
      >
        CLAIM
      </button>
    );
  }

  return (
    <>
      <NftNav />
      <header className="hero parallax" style={{ backgroundImage: 'url("/images/parallax/bg-1.png")' }}>
        <div className="hero-body">
          <div className="container">
            <div className="hero-box has-text-centered">
              <p className="title">NFT ArtWorks Airdrop</p>
              <p className="subtitle"><span className="has-text-primary">NFT's ArtWorks</span> power will be unique and generated randomly.</p>
            </div>
          </div>
        </div>
      </header>
      <main role="main" className="section">
        <div className="container">
          <div className="columns is-multiline is-justify-content-center">
            <div className="column is-half-desktop">
              <div className="card mb-5">
                <div className="card-content">
                  { renderApproveOrClaimButton() }
                </div>
                {nftAirdropState.firstLoad ? null : (
                  <div className="card-footer">
                    { startDiff > 0 ? (
                      <a href={ `${process.env.REACT_APP_EXPLORER}/block/countdown/${process.env.REACT_APP_NFT_AIRDROP_START}` } target="_blank" rel="noreferrer" className="card-footer-item">
                        <span className="icon-text">
                          <span className="icon">
                            <FontAwesomeIcon icon={ faStopwatch } />
                          </span>
                          Blocks remaining to start { startDiff }
                        </span>
                      </a>
                    ) : null}
                    {startDiff < 0 && endDiff > 0 ? (
                      <a href={ `${process.env.REACT_APP_EXPLORER}/block/countdown/${new BigNumber(nftAirdropState.endBlock).toString()}` } target="_blank" rel="noreferrer" className="card-footer-item">
                        <span className="icon-text">
                          <span className="icon">
                            <FontAwesomeIcon icon={ faStopwatch } />
                          </span>
                          Blocks remaining to end { endDiff }
                        </span>
                      </a>
                    ) : null}
                    { endDiff < 0 ? (
                      <a href={`${process.env.REACT_APP_EXPLORER}/block/countdown/${new BigNumber(nftAirdropState.endBlock).toString()}`} target="_blank" rel="noreferrer" className="card-footer-item">
                        <span className="icon-text">
                          <span className="icon">
                            <FontAwesomeIcon icon={ faStopwatch } />
                          </span>
                          Airdrop Finished
                        </span>
                      </a>
                    ) : null}
                  </div>
                )}
              </div>
              {startDiff < 0 && endDiff > 0 && !nftAirdropState.userCanClaim ? (
                <div className="notification is-danger is-light mb-3">
                  * Your address in unreachable in the top 50 holders or your NFT was already claimed.
                </div>
              ) : null}
              <NftInfo />
            </div>
            <div className="column is-half-desktop">
              <NftCard nftData={{
                pid: 2110,
                experience: 287,
                generation: 0,
                power: 27
              }} />
            </div>
          </div>
        </div>
      </main>
      <div className="parallax is-hidden-mobile" style={{ backgroundImage: 'url("/images/parallax/bg-1.png")', height: '100vh' }} />
    </>
  );
}

export default NftAirdropPage;
