const contractsMainNet = new Map();
const contractsTestNet = new Map();

// internal
contractsMainNet.set('masterChef', '0x64aB872a2937dE057F21c8e0596C0175FF2084d8');
contractsMainNet.set('multiCall', '0x4EDAa97837797b51eA626d1dD62793145F1b3f1a');

contractsMainNet.set('vaultChef', '0xceE8359603Cf4908FBed639218fAe779F4710390');

contractsMainNet.set('nftChef', '0x942d791ab07e33Fe4B780Fc0b3874a24Ac3da433');
contractsMainNet.set('nftSale0', '0xFa6f18Fe72325Fe2C66615295b7cD09fc97F879c');
contractsMainNet.set('nftSale1', '0x52725E0b104d6728dC727D66cce5F38fE1656E53');
contractsMainNet.set('nftSale2', '0x8A47E4E103ca99e812dBfAc5C03F16FC12F38a93');
contractsMainNet.set('nftSale3', '0xEd42076Aa4c083d3F732B21B7b200120C43a74dB');
contractsMainNet.set('nftAirdrop', '0xb4F774a9D7484AfFb67BD439D19ae04a155a85b9');
contractsMainNet.set('nftMerge', '0x503d2E56055c7078905369D7fA43c6f20C70a43a');

contractsMainNet.set('preBanksy', '0x24aB3Af219130E6d5edA18145E3cB19582fDFd16');
contractsMainNet.set('preBanksySwap', '0xaA4A6e43Ed5B5811D1488D0e065e1Dc94B378bB1');
// single tokens
contractsMainNet.set('banksy', '0x9C26e24ac6f0EA783fF9CA2bf81543c67cf446d2');
contractsMainNet.set('avax', '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7');
contractsMainNet.set('btc', '0x50b7545627a5162f82a992c33b87adc75187b218');
contractsMainNet.set('eth', '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB');
contractsMainNet.set('usdc', '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664');
contractsMainNet.set('usdt', '0xc7198437980c041c805a1edcba50c1ce5db95118');
contractsMainNet.set('link', '0x5947BB275c521040051D82396192181b413227A3');
contractsMainNet.set('dai', '0xd586e7f844cea2f87f50152665bcbc2c279d8d70');
contractsMainNet.set('sing', '0xF9A075C9647e91410bF6C402bDF166e1540f67F0');
// LPs
contractsMainNet.set('banksy-avax', '0xF379b14A5f366822fA435320CE10cBCB07E24749');
contractsMainNet.set('banksy-usdt', '0xcF34f203046e8A8f64F58f33b01Bb7132e859450');
contractsMainNet.set('avax-usdt', '0xeD8CBD9F0cE3C6986b22002F03c6475CEb7a6256');
contractsMainNet.set('avax-usdc', '0xA389f9430876455C36478DeEa9769B7Ca4E3DDB1');
contractsMainNet.set('usdc-usdt', '0x2E02539203256c83c7a9F6fA6f8608A32A2b1Ca2');
contractsMainNet.set('eth-avax', '0xFE15c2695F1F920da45C30AAE47d11dE51007AF9');
// LPs utilities
contractsMainNet.set('link-avax', '0x6F3a0C89f611Ef5dC9d96650324ac633D02265D3');
contractsMainNet.set('btc-avax', '0xd5a37dc5c9a396a03dd1136fc76a1a02b1c88ffa');
contractsMainNet.set('dai-avax', '0x87Dee1cC9FFd464B79e058ba20387c1984aed86a');
contractsMainNet.set('sing-avax', '0xECdD9519f20ba7d4c582CbaA7Db8b3C457906586');
// Fake
contractsMainNet.set('preBanksy-avax', '0xF379b14A5f366822fA435320CE10cBCB07E24749'); // for pool#1
// contractsMainNet.set('nftChef', '0x7643510A4335b2aC92597761c8709d3BDCBA2c16'); // FIXME

// contractsMainNet.set('fusdt', '0xBA83e4D32E805bf5a800273acF94D3c564621bcC');
// contractsMainNet.set('fdai', '0x64D4545Cf9e2F0dFFCFdfb0D300Fddc538A14aC6');
// contractsMainNet.set('fbanksy', '0xdB1d67c070D4D4aE74E70A4EB790E3b643E9a30c');
// contractsMainNet.set('fbanksy-avax', '0xa9fd3FBb9af4736ea62994f3618a23b1a9e3dbE3');
// contractsMainNet.set('fbanksy-usdt', '0x990F27a2944aB70Dca1bCF0334Cb7ED28e91081a');

export { contractsMainNet, contractsTestNet };
