import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import { faCheck, faCog, faExternalLinkAlt, faStopwatch, faSync, faTractor } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HomeNav from '../home/components/HomeNav';
import PreBanksyBuyModal from './PreBanksyBuyModal';
import NftCard from '../nft/components/NftCard';

import { fetchPreSale, approveBuyPreBanksy, approveSwapPreBanksy, swapPreBanksyForBanksy } from '../../blockchain/preSale';

import { WalletContext } from '../../context/wallet';
import { BlockContext } from '../../context/block';
import { ToastContext } from '../../context/toast';

const PresalePage = () => {
  const { walletAddress, handleConnectClick } = useContext(WalletContext);
  const { currentBlock } = useContext(BlockContext);
  const { addToast } = useContext(ToastContext);

  const [width, setWidth] = useState(window.innerWidth);
  const [diff, setDiff] = useState({
    preBanksyStart: 0,
    preBanksyEnd: 0,
    preBanksySwapStart: 0,
    masterChefStart: 0,
    nftSale0Start: 0,
    nftSale1Start: 0,
    nftSale2Start: 0,
    wall0Start: 0,
    wall1Start: 0,
    wall2Start: 0,
  });
  const [pendingBuyTx, setPendingBuyTx] = useState(false);
  const [pendingSwapTx, setPendingSwapTx] = useState(false);

  const [preSaleState, setPreSaleState] = useState({
    firstLoad: true,
    preBanksyStartBlock: 0,
    preBanksyEndBlock: 0,
    preBanksyPerAccountMaxTotal: 0,
    preBanksyRemaining: 0,
    salePriceE35: 0,
    preBanksyDecimals: 0,
    preBanksyBalance: 0,
    userPreBanksySwapAllowance: 0,
    userPreBanksyBalance: 0,

    preBanksySwapStartBlock: 0,

    preBanksySwapBalance: 0,
    nativeTokenDecimals: 0,
    tokenDecimals: 0,
    userPreBanksyAllowance: 0,
    userTokenBalance: 0,
    userNativeTokenBalance: 0,

    masterChefStartBlock: 0,

    nftSale0StartBlock: 0,
    nftSale1StartBlock: 0,
    nftSale2StartBlock: 0,

    soldOut: false,
  });
  const [isPreBanksyBuyModalActive, setIsPreBanksyBuyModalActive] = useState(false);

  const handlePreBanksyBuyModalClose = () => setIsPreBanksyBuyModalActive(false);
  const handlePreBanksyBuyModalOpen = () => setIsPreBanksyBuyModalActive(true);

  const handleWindowSizeChange = () => setWidth(window.innerWidth);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const syncPreSale = async () => {
      setPreSaleState(prevState => ({ ...prevState, loading: true }));
      const rs = await fetchPreSale();
      setPreSaleState(prevState => ({ ...prevState, ...rs, loading: false }));
    }

    const intervalId = setInterval(syncPreSale, 5000);

    syncPreSale();

    return () => clearInterval(intervalId);
  }, [setPreSaleState]);

  useEffect(() => {
    if (currentBlock) {
      setDiff({
        preBanksyStart: new BigNumber(preSaleState.preBanksyStartBlock).minus(currentBlock).toNumber(),
        preBanksyEnd: new BigNumber(preSaleState.preBanksyEndBlock).minus(currentBlock).toNumber(),
        preBanksySwapStart: new BigNumber(preSaleState.preBanksySwapStartBlock).minus(currentBlock).toNumber(),
        masterChefStart: new BigNumber(preSaleState.masterChefStartBlock).minus(currentBlock).toNumber(),
        nftSale0Start: new BigNumber(preSaleState.nftSale0StartBlock).minus(currentBlock).toNumber(),
        nftSale1Start: new BigNumber(preSaleState.nftSale1StartBlock).minus(currentBlock).toNumber(),
        nftSale2Start: new BigNumber(preSaleState.nftSale2StartBlock).minus(currentBlock).toNumber(),
        wall0Start: new BigNumber(process.env.REACT_APP_WALL_0_START).minus(currentBlock).toNumber(),
        wall1Start: new BigNumber(process.env.REACT_APP_WALL_1_START).minus(currentBlock).toNumber(),
        wall2Start: new BigNumber(process.env.REACT_APP_WALL_2_START).minus(currentBlock).toNumber(),
        nftAirdropStart: new BigNumber(process.env.REACT_APP_NFT_AIRDROP_START).minus(currentBlock).toNumber(),
      });
    }
  }, [currentBlock, setDiff, preSaleState]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  const handleBuyApprove = async () => {
    setPendingBuyTx(true);
    let tx;
    try {
      tx = await approveBuyPreBanksy('usdt');
      await tx.wait();
      addToast('PreBanksy Token approve succeeded', 'is-success');
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }

    if(tx?.error !== undefined) {
      console.log('error', tx.error);
      addToast('PreBanksy Token approve failed', 'is-danger');
    }

    setPendingBuyTx(false);
  }

  const renderUnlockOrApproveOrBuyButton = () => {
    if (walletAddress === null) {
      return (
        <button className="button is-primary is-fullwidth" onClick={ handleConnectClick }>
          Unlock
        </button>
      );
    }

    const isNotActive = diff.preBanksyStart > 0 || diff.preBanksyEnd < 0;

    if (new BigNumber(preSaleState.userPreBanksyAllowance).eq(0)) {
      return (
        <button
          type="button"
          disabled={ isLoading() || pendingBuyTx || preSaleState.soldOut || isNotActive }
          className={ `button is-primary is-fullwidth ${pendingBuyTx ? 'is-loading' : ''}` }
          onClick={ handleBuyApprove }
        >
          APPROVE USDT.e
        </button>
      );
    }

    return (
      <button
        type="button"
        disabled={ isLoading() || isNotActive || preSaleState.soldOut }
        className="button is-primary is-fullwidth"
        onClick={ () => handlePreBanksyBuyModalOpen() }
      >
        Buy
      </button>
    );
  }

  const handleSwapApprove = async () => {
    setPendingSwapTx(true);
    let tx;
    try {
      tx = await approveSwapPreBanksy();
      await tx.wait();
      addToast('PreBanksy token Swap approve succeeded', 'is-success');
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }

    if(tx?.error !== undefined) {
      console.log('error', tx.error);
      addToast('PreBanksy token Swap approve failed', 'is-danger');
    }

    setPendingSwapTx(false);
  }

  const handleSwap = async () => {
    setPendingSwapTx(true);
    let tx;
    try {
      tx = await swapPreBanksyForBanksy();
      await tx.wait();
      addToast('PreBanksy Swap succeeded', 'is-success');
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }

    if(tx?.error !== undefined) {
      console.log('error', tx.error);
      addToast('PreBanksy Swap failed', 'is-danger');
    }

    setPendingSwapTx(false);
  }

  const renderUnlockOrApproveOrSwapButton = () => {
    if (walletAddress === null) {
      return (
        <button className="button is-primary is-fullwidth" onClick={ handleConnectClick }>
          Unlock
        </button>
      );
    }

    const isNotActive = diff.preBanksySwapStart > 0;

    if (new BigNumber(preSaleState.userPreBanksySwapAllowance).eq(0)) {
      return (
        <button
          type="button"
          disabled={ isLoading() || pendingSwapTx || isNotActive }
          className={ `button is-primary is-fullwidth ${pendingSwapTx ? 'is-loading' : ''}` }
          onClick={ handleSwapApprove }
        >
          APPROVE PRE-BANKSY
        </button>
      );
    }

    return (
      <button
        type="button"
        disabled={ isLoading() || pendingSwapTx || isNotActive || new BigNumber(preSaleState.userPreBanksyBalance).eq(0) }
        className={ `button is-primary is-fullwidth ${pendingSwapTx ? 'is-loading' : ''}` }
        onClick={ () => handleSwap() }
      >
        SWAP
      </button>
    );
  }

  const isLoading = () => preSaleState.firstLoad && preSaleState.loading;

  const renderRemainingOrSoldOut = () => {
    const rm = new BigNumber(preSaleState.preBanksyRemaining)
      .div(new BigNumber(10).pow(preSaleState.preBanksyDecimals));

    return rm.eq(0) ? 'SOLD OUT' : rm.toFormat(2);
  }

  const liquidityProvidedDiff = Number(process.env.REACT_APP_LAUNCH_AT) - Date.now();

  return (
    <>
      <HomeNav />
      <header className="hero parallax" style={{ backgroundImage: 'url("/images/parallax/bg-1.png")' }}>
        <div className="hero-body">
          <div className="container">
            <div className="hero-box has-text-centered">
              <p className="title">Banksy Timeline</p>
              <p className="subtitle">
                Check our Timeline in <span className="has-text-primary">Realtime</span>. Our contracts executes like <span className="has-text-primary">DAO</span>.
              </p>
            </div>
          </div>
        </div>
      </header>
      <main role="main" className="section">
        <div className="container">
          <div className={ `timeline ${width > 768 ? 'is-centered' : null}` }>
            <header className="timeline-header">
              <span className="tag is-medium is-rounded is-primary">
                { isLoading() ? <FontAwesomeIcon icon={ faCog } spin /> : <>Start</> }
              </span>
            </header>
            <div className={ `timeline-item ${isLoading() || diff.preBanksyStart > 0 ? 'is-primary' : 'is-danger' }` }>
              <div className={ `timeline-marker is-icon is-medium ${isLoading() || diff.preBanksyStart > 0 ? 'is-primary' : 'is-danger' }` }>
                <FontAwesomeIcon icon={ isLoading() || diff.preBanksyStart > 0 ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  DEC 14th, 15:00 UTC approx
                  <a href={ `${process.env.REACT_APP_EXPLORER}/block/countdown/${ new BigNumber(preSaleState.preBanksyStartBlock).toFixed() }` } target="_blank" rel="noreferrer" className="ml-1">
                    <span className="icon-text">
                      { new BigNumber(preSaleState.preBanksyStartBlock).toFixed() }
                      <span className="icon">
                        <FontAwesomeIcon icon={ faExternalLinkAlt } />
                      </span>
                    </span>
                  </a>
                </p>
                <div>
                  <div className="card mb-3">
                    <div className="card-header">
                      <p className="card-header-title">PRESALE PRE-BANKSY</p>
                    </div>
                    <div className="card-content">
                      PRE-BANKSY Remaining: { isLoading() ? <FontAwesomeIcon icon={ faCog } spin /> : <strong className="has-text-primary">{ renderRemainingOrSoldOut() }</strong> }<br />
                      Your PRE-BANKSY balance: { isLoading() ? <FontAwesomeIcon icon={ faCog } spin /> : <strong className="has-text-primary">{ new BigNumber(preSaleState.userPreBanksyBalance).div(new BigNumber(10).pow(preSaleState.preBanksyDecimals)).toFormat(2) }</strong> }<br />
                      Your USDT.e balance: { isLoading() ? <FontAwesomeIcon icon={ faCog } spin /> : <strong className="has-text-primary">${ new BigNumber(preSaleState.userTokenBalance).div(new BigNumber(10).pow(preSaleState.tokenDecimals)).toFormat(2) }</strong> }<br />
                      You can buy up to: { isLoading() ? <FontAwesomeIcon icon={ faCog } spin /> : <strong className="has-text-primary">{ new BigNumber(preSaleState.preBanksyPerAccountMaxTotal).div(new BigNumber(10).pow(preSaleState.preBanksyDecimals)).toFormat(2) }</strong> } PRE-BANKSY<br /><br />
                      { renderUnlockOrApproveOrBuyButton() }
                      <h5 className="is-size-5 has-text-bold has-text-primary has-text-centered mt-3">Only ${ new BigNumber(preSaleState.salePrice).toFormat(2) } per Token</h5>
                    </div>
                    <div className="card-footer">
                      {diff.preBanksyStart > 0 ? (
                        <a href={ `${process.env.REACT_APP_EXPLORER}/block/countdown/${ new BigNumber(preSaleState.preBanksyStartBlock).toFixed() }` } target="_blank" rel="noreferrer" className="card-footer-item">
                          Blocks remaining to start { diff.preBanksyStart }
                        </a>
                      ) : null}
                      {diff.preBanksyStart < 0 && diff.preBanksyEnd > 0 ? (
                        <a href={ `${process.env.REACT_APP_EXPLORER}/block/countdown/${ new BigNumber(preSaleState.preBanksyEndBlock).toFixed() }` } target="_blank" rel="noreferrer" className="card-footer-item">
                          Blocks remaining to end { diff.preBanksyEnd }
                        </a>
                      ) : null}
                      {!isLoading() && diff.preBanksyEnd < 0 ? (
                        <a href={ `${process.env.REACT_APP_EXPLORER}/block/countdown/${ new BigNumber(preSaleState.preBanksyEndBlock).toFixed() }` } target="_blank" rel="noreferrer" className="card-footer-item" style={{ textDecoration: 'line-through' }}>
                          PRE-SALE ENDED
                        </a>
                      ) : null}
                    </div>
                  </div>
                  <div className="has-text-info">
                    * Buy PRE-BANKSY token using USDT.e
                  </div>
                </div>
              </div>
            </div>

            <div className={ `timeline-item ${isLoading() || diff.nftSale0Start > 0 ? 'is-primary' : 'is-danger'}` }>
              <div className={ `timeline-marker is-icon is-medium ${isLoading() || diff.nftSale0Start > 0 ? 'is-primary' : 'is-danger'}` }>
                <FontAwesomeIcon icon={ isLoading() || diff.nftSale0Start > 0 ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Dec 14th, 17:00 UTC approx - 
                  <a href={ `${process.env.REACT_APP_EXPLORER}/block/countdown/${ new BigNumber(preSaleState.nftSale0StartBlock).toFixed() }` } target="_blank" rel="noreferrer" className="ml-1">
                    <span className="icon-text">
                      { new BigNumber(preSaleState.nftSale0StartBlock).toFixed() }
                      <span className="icon">
                        <FontAwesomeIcon icon={ faExternalLinkAlt } />
                      </span>
                    </span>
                  </a>
                </p>
                <p>NFT SALE - EARLY ACCESS 1</p>
                <div>
                  <NftCard nftData={{
                    pid: 2110,
                    experience: 287,
                    generation: 0,
                    power: 27
                  }} hideDetails />
                  <button type="button" className="button is-primary is-fullwidth" disabled>
                    SOLD OUT
                  </button>
                </div>
              </div>
            </div>

            <div className={ `timeline-item ${ isLoading() || diff.wall0Start > 0 ? 'is-primary' : 'is-danger' }` }>
              <div className={ `timeline-marker is-icon is-medium ${ isLoading() || diff.wall0Start > 0 ? 'is-primary' : 'is-danger' }` }>
                <FontAwesomeIcon icon={ isLoading() || diff.wall0Start > 0 ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Dec 14th, 22:00 UTC approx - 
                  <a href={ `${process.env.REACT_APP_EXPLORER}/block/countdown/${ process.env.REACT_APP_WALL_0_START }` } target="_blank" rel="noreferrer" className="ml-1">
                    <span className="icon-text">
                      { process.env.REACT_APP_WALL_0_START }
                      <span className="icon">
                        <FontAwesomeIcon icon={ faExternalLinkAlt } />
                      </span>
                    </span>
                  </a>
                </p>
                <p>PRE-BANKSY WALL (dividends pool)</p>
                <div>
                  <div className="p-3">
                    <figure className="image is-128x128 mb-3">
                      <img src="/images/pre-banksy.png" alt="PRE-BANKSY" />
                    </figure>
                    <Link to="/walls" disabled className="button is-primary">Finished</Link>
                  </div>
                </div>
              </div>
            </div>

            <div className={ `timeline-item ${isLoading() || diff.nftSale1Start > 0 ? 'is-primary' : 'is-danger'}` }>
              <div className={ `timeline-marker is-icon is-medium ${isLoading() || diff.nftSale1Start > 0 ? 'is-primary' : 'is-danger'}` }>
                <FontAwesomeIcon icon={ isLoading() || diff.nftSale1Start > 0 ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Dec 16th, 15:00 UTC approx - 
                  <a href={ `${process.env.REACT_APP_EXPLORER}/block/countdown/${ new BigNumber(preSaleState.nftSale1StartBlock).toFixed() }` } target="_blank" rel="noreferrer" className="ml-1">
                    <span className="icon-text">
                      { new BigNumber(preSaleState.nftSale1StartBlock).toFixed() }
                      <span className="icon">
                        <FontAwesomeIcon icon={ faExternalLinkAlt } />
                      </span>
                    </span>
                  </a>
                </p>
                <p>NFT SALE - EARLY ACCESS 2</p>
                <div>
                  <NftCard nftData={{
                    pid: 2110,
                    experience: 287,
                    generation: 0,
                    power: 27
                  }} hideDetails />
                  <button type="button" className="button is-primary is-fullwidth" disabled>
                    SOLD OUT
                  </button>
                </div>
              </div>
            </div>

            <div className={ `timeline-item ${ isLoading() || diff.preBanksyEnd > 0 ? 'is-primary' : 'is-danger' }` }>
              <div className={ `timeline-marker is-icon is-medium ${ isLoading() || diff.preBanksyEnd > 0 ? 'is-primary' : 'is-danger' }` }>
                <FontAwesomeIcon icon={ isLoading() || diff.preBanksyEnd > 0 ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Dec 17th, 19:00 UTC approx - 
                  <a href={ `${process.env.REACT_APP_EXPLORER}/block/countdown/${ new BigNumber(preSaleState.preBanksyEndBlock).toFixed() }` } target="_blank" rel="noreferrer" className="ml-1">
                    <span className="icon-text">
                      { new BigNumber(preSaleState.preBanksyEndBlock).toFixed() }
                      <span className="icon">
                        <FontAwesomeIcon icon={ faExternalLinkAlt } />
                      </span>
                    </span>
                  </a>
                </p>
                <p>Presale End</p>
              </div>
            </div>

            <div className={ `timeline-item ${isLoading() || diff.nftAirdropStart > 0 ? 'is-primary' : 'is-danger'}` }>
              <div className={ `timeline-marker is-icon is-medium ${isLoading() || diff.nftAirdropStart > 0 ? 'is-primary' : 'is-danger'}` }>
                <FontAwesomeIcon icon={ isLoading() || diff.nftAirdropStart > 0 ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Dec 18th, 14:00 UTC approx - 
                  <a href={ `${process.env.REACT_APP_EXPLORER}/block/countdown/${ new BigNumber(process.env.REACT_APP_NFT_AIRDROP_START).toFixed() }` } target="_blank" rel="noreferrer" className="ml-1">
                    <span className="icon-text">
                      { new BigNumber(process.env.REACT_APP_NFT_AIRDROP_START).toFixed() }
                      <span className="icon">
                        <FontAwesomeIcon icon={ faExternalLinkAlt } />
                      </span>
                    </span>
                  </a>
                </p>
                <p>ARTWORK NFT AIRDROP (MORONS)</p>
                <div>
                  <NftCard nftData={{
                    pid: 2110,
                    experience: 287,
                    generation: 0,
                    power: 27
                  }} hideDetails />
                  <button type="button" disabled className="button is-primary is-fullwidth mb-3">
                    Finished
                  </button>
                  <div className="has-text-info">
                    * Only 50 NFT for AIRDROP.
                  </div>
                </div>
              </div>
            </div>

            <div className={ `timeline-item ${isLoading() || liquidityProvidedDiff > 0 ? 'is-primary' : 'is-danger' }` }>
              <div className={ `timeline-marker is-icon is-medium ${isLoading() || liquidityProvidedDiff > 0 ? 'is-primary' : 'is-danger' }` }>
                <FontAwesomeIcon icon={ isLoading() || liquidityProvidedDiff > 0 ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Dec 18th, 15:00 UTC
                </p>
                <p>Liquidity Provided</p>
                <div className="p-3">
                  <figure className="image is-128x128 mb-3">
                    <img src="/images/farms/banksy.png" alt="BANKSY" />
                  </figure>
                </div>
              </div>
            </div>

            <div className={ `timeline-item ${isLoading() || diff.preBanksySwapStart > 0 ? 'is-primary' : 'is-danger' }` }>
              <div className={ `timeline-marker is-icon is-medium ${isLoading() || diff.preBanksySwapStart > 0 ? 'is-primary' : 'is-danger' }` }>
                <FontAwesomeIcon icon={ isLoading() || diff.preBanksySwapStart > 0 ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  DEC 18th, 16:00 UTC approx
                  <a href={ `${process.env.REACT_APP_EXPLORER}/block/countdown/${ new BigNumber(preSaleState.preBanksySwapStartBlock).toFixed() }` } target="_blank" rel="noreferrer" className="ml-1">
                    <span className="icon-text">
                      { new BigNumber(preSaleState.preBanksySwapStartBlock).toFixed() }
                      <span className="icon">
                        <FontAwesomeIcon icon={ faExternalLinkAlt } />
                      </span>
                    </span>
                  </a>
                </p>
                <div>
                  <div className="card mb-3">
                    <div className="card-header">
                      <p className="card-header-title">BANKSY Redeem</p>
                    </div>
                    <div className="card-content">
                      BANKSY Remaining: { isLoading() ? <FontAwesomeIcon icon={ faCog } spin /> : <strong className="has-text-primary">{ new BigNumber(preSaleState.preBanksySwapBalance).div(new BigNumber(10).pow(preSaleState.nativeTokenDecimals)).toFormat(2) }</strong> }<br />
                      Your BANKSY balance: { isLoading() ? <FontAwesomeIcon icon={ faCog } spin /> : <strong className="has-text-primary">{ new BigNumber(preSaleState.userNativeTokenBalance).div(new BigNumber(10).pow(preSaleState.nativeTokenDecimals)).toFormat(2) }</strong> }<br />
                      You can swap for up to: { isLoading() ? <FontAwesomeIcon icon={ faCog } spin /> : <strong className="has-text-primary">{ new BigNumber(preSaleState.userPreBanksyBalance).div(new BigNumber(10).pow(preSaleState.preBanksyDecimals)).toFormat(2) }</strong> } BANKSY<br />
                      Rate: 1 : 1 Swap<br /><br />
                      { renderUnlockOrApproveOrSwapButton() }
                    </div>
                    {diff.preBanksyStart > 0 ? (
                      <div className="card-footer">
                        <a href={ `${process.env.REACT_APP_EXPLORER}/block/countdown/${ new BigNumber(preSaleState.preBanksySwapStartBlock).toFixed() }` } target="_blank" rel="noreferrer" className="card-footer-item">
                          Blocks remaining to start { diff.preBanksySwapStart }
                        </a>
                      </div>
                    ) : null}
                  </div>
                  <div className="has-text-info">
                    * Swap your PRE-BANKSY per BANKSY.
                  </div>
                </div>
              </div>
            </div>

            <div className={ `timeline-item ${ isLoading() || diff.wall1Start > 0 ? 'is-primary' : 'is-danger' }` }>
              <div className={ `timeline-marker is-icon is-medium ${ isLoading() || diff.wall1Start > 0 ? 'is-primary' : 'is-danger' }` }>
                <FontAwesomeIcon icon={ isLoading() || diff.wall1Start > 0 ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Dec 18th, 19:00 UTC approx - 
                  <a href={ `${process.env.REACT_APP_EXPLORER}/block/countdown/${ process.env.REACT_APP_WALL_1_START }` } target="_blank" rel="noreferrer" className="ml-1">
                    <span className="icon-text">
                      { process.env.REACT_APP_WALL_1_START }
                      <span className="icon">
                        <FontAwesomeIcon icon={ faExternalLinkAlt } />
                      </span>
                    </span>
                  </a>
                </p>
                <p>BANKSY-DAI WALL (dividends pool)</p>
                <div>
                  <div className="p-3">
                    <figure className="image is-128x128 mb-3">
                      <img src="/images/farms/banksy-dai-wall.png" alt="PRE-BANKSY" />
                    </figure>
                    <button type="button" disabled to="/walls" className="button is-primary">Finished</button>
                  </div>
                </div>
              </div>
            </div>

            <div className={ `timeline-item ${isLoading() || diff.nftSale2Start > 0 ? 'is-primary' : 'is-danger'}` }>
              <div className={ `timeline-marker is-icon is-medium ${isLoading() || diff.nftSale2Start > 0 ? 'is-primary' : 'is-danger'}` }>
                <FontAwesomeIcon icon={ isLoading() || diff.nftSale2Start > 0 ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Dec 20th, 15:00 UTC approx - 
                  <a href={ `${process.env.REACT_APP_EXPLORER}/block/countdown/${ new BigNumber(preSaleState.nftSale2StartBlock).toFixed() }` } target="_blank" rel="noreferrer" className="ml-1">
                    <span className="icon-text">
                      { new BigNumber(preSaleState.nftSale2StartBlock).toFixed() }
                      <span className="icon">
                        <FontAwesomeIcon icon={ faExternalLinkAlt } />
                      </span>
                    </span>
                  </a>
                </p>
                <p>NFT SALE - EARLY ACCESS 3</p>
                <div>
                  <NftCard nftData={{
                    pid: 2110,
                    experience: 287,
                    generation: 0,
                    power: 27
                  }} hideDetails />
                  <button type="button" disabled className="button is-primary is-fullwidth">
                    SOLD OUT
                  </button>
                </div>
              </div>
            </div>

            <div className={ `timeline-item ${isLoading() || diff.masterChefStart > 0 ? 'is-primary' : 'is-danger'}` }>
              <div className={ `timeline-marker is-icon is-medium ${isLoading() || diff.masterChefStart > 0 ? 'is-primary' : 'is-danger'}` }>
                <FontAwesomeIcon icon={ isLoading() || diff.masterChefStart > 0 ? faTractor : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  DEC 21st, 15:00 UTC approx
                  <a href={ `${process.env.REACT_APP_EXPLORER}/block/countdown/${ new BigNumber(preSaleState.masterChefStartBlock).toFixed() }` } target="_blank" rel="noreferrer" className="ml-1">
                    <span className="icon-text">
                      { new BigNumber(preSaleState.masterChefStartBlock).toFixed() }
                      <span className="icon">
                        <FontAwesomeIcon icon={ faExternalLinkAlt } />
                      </span>
                    </span>
                  </a>
                </p>
                <p>Farming Start</p>
              </div>
            </div>

            <div className={ `timeline-item ${ isLoading() || diff.wall2Start > 0 ? 'is-primary' : 'is-danger' }` }>
              <div className={ `timeline-marker is-icon is-medium ${ isLoading() || diff.wall2Start > 0 ? 'is-primary' : 'is-danger' }` }>
                <FontAwesomeIcon icon={ isLoading() || diff.wall2Start > 0 ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Dec 21st, 19:00 UTC approx - 
                  <a href={ `${process.env.REACT_APP_EXPLORER}/block/countdown/${ process.env.REACT_APP_WALL_2_START }` } target="_blank" rel="noreferrer" className="ml-1">
                    <span className="icon-text">
                      { process.env.REACT_APP_WALL_2_START }
                      <span className="icon">
                        <FontAwesomeIcon icon={ faExternalLinkAlt } />
                      </span>
                    </span>
                  </a>
                </p>
                <p>BANKSY-DAI WALL (dividends pool)</p>
                <div>
                  <div className="p-3">
                    <figure className="image is-128x128 mb-3">
                      <img src="/images/farms/banksy-xmass-wall.png" alt="PRE-BANKSY" />
                    </figure>
                    <button type="button" disabled className="button is-primary">Finished</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="timeline-item is-primary">
              <div className="timeline-marker is-primary is-icon is-medium">
                <FontAwesomeIcon icon={ faSync } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  TBA
                </p>
                <p>Vaults Start</p>
              </div>
            </div>

            <header className="timeline-header">
              <span className="tag is-medium is-rounded is-primary">To the Moon!</span>
            </header>
          </div>
        </div>
      </main>
      <div className="parallax is-hidden-mobile" style={{ backgroundImage: 'url("/images/parallax/bg-1.png")', height: '100vh' }} />
      <PreBanksyBuyModal
        preSaleState={ preSaleState }
        isModalActive={ isPreBanksyBuyModalActive }
        onModalClose={ handlePreBanksyBuyModalClose }
      />
    </>
  );
}

export default PresalePage;
