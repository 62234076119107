import React, { useState, useContext } from 'react';
import BigNumber from 'bignumber.js';

import { getAddress } from '../../../utils/commons';
import { approveNft } from '../../../blockchain/nft';

import { ToastContext } from '../../../context/toast';

const NftMergeCard = ({ nftData, ribbon = '', selected = false, disabled = false, toggleArtWorkId, hideActions = false }) => {
  const { addToast } = useContext(ToastContext);

  const [pendingTx, setPendingTx] = useState(false);
  const nftMergeAddress = getAddress('nftMerge');

  const nftName = () => {
    return `Morons #${nftData.pid}`;
  }

  const handleApprove = async () => {
    setPendingTx(true);
    let tx;
    try {
      tx = await approveNft(nftMergeAddress, nftData.pid);
      await tx.wait();
      addToast('ArtWork Approve succeeded', 'is-success');
    } catch (error) {
      tx = { error: error.message };
    }

    if(tx?.error !== undefined) {
      console.log('error', tx.error);
      addToast('ArtWork Approve failed', 'is-danger');
    }

    setPendingTx(false);
  }

  const renderActions = () => {
    if (nftData.mergeCount > 0) {
      return (<p className="has-primary-text">Merged ArtWork</p>);
    }

    if (nftData.busy) {
      return (<p className="has-primary-text">Busy Artwork</p>);
    }

    if (nftData.allowance !== nftMergeAddress) {
      return (
        <button
          className={ `button is-primary is-fullwidth ${pendingTx ? 'is-loading' : ''}` }
          disabled={ pendingTx || disabled }
          onClick={ handleApprove }
        >
          Approve
        </button>
      );
    }

    return (
      <div className="field">
        <input
          id={ `pid-${nftData.pid}` }
          type="checkbox"
          name={ `pid-${nftData.pid}` }
          className="switch is-rounded is-outlined"
          checked={ selected }
          onChange={ () => toggleArtWorkId(nftData.pid, nftData.generation) }
          disabled={ disabled && !selected }
        />
        <label htmlFor={ `pid-${nftData.pid}` }>Select</label>
      </div>
    );
  }

  return (
    <div className={ `box p-3 is-relative ${nftData.mergeCount > 0 ? 'gradient-border' : ''}` }>
      { ribbon !== '' ? (
        <div className="ribbon ribbon-top-right">
          <span>{ ribbon }</span>
        </div>
      ) : null }
      <figure className="image is-16by9 mb-3">
        <img src={ `/images/nfts/generation-${nftData.generation}.png` } alt="Banksy" />
      </figure>
      <p className="title mb-0">{ nftName() }</p>
      <div className="field is-grouped is-grouped-multiline p-3">
        <div className="control">
          <div className="tags has-addons">
            <span className="tag mb-0 is-dark">Gen.</span>
            <span className="tag mb-0 is-info">{ nftData.generation }</span>
          </div>
        </div>
        <div className="control">
          <div className="tags has-addons">
            <span className="tag mb-0 is-dark">Exp.</span>
            <span className="tag mb-0 is-success">{ new BigNumber(nftData.experience).div(new BigNumber(10).pow(process.env.REACT_APP_DECIMALS)).toFormat(0) }</span>
          </div>
        </div>
        <div className="control">
          <div className="tags has-addons">
            <span className="tag mb-0 is-dark">Power</span>
            <span className="tag mb-0 is-primary">{ nftData.power }</span>
          </div>
        </div>
      </div>
      {hideActions ? null : (
        <div className="p-3">
          { renderActions() }
        </div>
      )}
    </div>
  );
}

export default NftMergeCard;
